const regex = /\[LIEN:(.+)"(.+)"\]/gi;

tinymce.PluginManager.add('replacelink', function(editor, url) {
  // Add a button that opens a window
  editor.addButton('replacelink', {
    text: 'Convertir liens',
    icon: 'link',
    tooltip: 'Convertir les liens de la forme [LIEN:url "texte du lien"]',
    onclick: function() {
      replacement(editor);
    }
  });

  // On list focus trigger it
  editor.on('blur', function (e) {
    replacement(editor);
  });

  return {
    getMetadata: function () {
      return  {
        name: "Replace link plugin",
      };
    }
  };

});

function replacement(editor) {
  let matches = null;
  while( (matches = regex.exec(editor.getContent())) ) {
    if (matches !== null && matches[1] !== undefined && matches[2] !== undefined) {
      if (matches[1].substring(0, 7) !== 'http://' && matches[1].substring(0, 8) !== 'https://') {
        matches[1] = 'http://' + matches[1];
      }
      editor.setContent(editor.getContent().replace(matches[0], '<a href="'+matches[1].trim()+'">'+matches[2].trim()+'</a>'));
    }
  }
}
