// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/modern/theme';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import '../../static/tinymce/plugins/replacelink';

class Editor {

    constructor(editor, options) {
        this.editor = editor;
        this.options = options;
        this.tinymceEditor = null;
        if (this.options.selector && this.options.selector.substring(0, 1) === '#') {
            this.tinymceEditor = tinymce.get(this.options.selector.substring(1));
        } else if (this.options.target && this.options.target.getAttribute('id') && this.options.target.getAttribute('id').length) {
            this.tinymceEditor = tinymce.get(this.options.target.getAttribute('id'));
        }
    }

    focus() {
        if (this.tinymceEditor !== null) {
            this.tinymceEditor.focus();
        } else {
            console.log('Make sure to instanciate with an ID or a DOM Element with an ID attribute.');
        }
    }

    setMode(mode) {
        if (this.tinymceEditor !== null) {
            this.tinymceEditor.setMode(mode);
        } else {
            console.log('Make sure to instanciate with an ID or a DOM Element with an ID attribute.');
        }
    }

    setData(data) {
        if (this.tinymceEditor !== null) {
            this.tinymceEditor.setContent(data);
        } else {
            console.log('Make sure to instanciate with an ID or a DOM Element with an ID attribute.');
        }
    }

    resizeTo(w, h) {
        if (this.tinymceEditor !== null) {
            this.tinymceEditor.theme.resizeTo(w, h);
        } else {
            console.log('Make sure to instanciate with an ID or a DOM Element with an ID attribute.');
        }
    }

    static init(selectorOrTarget, opts = {}) {
        if (!opts['autoresize_max_height']) {
            opts['autoresize_max_height'] = 800;
        }

        // Extract content_css from opts & merge it with generic one (& unset var)
        var contentCss = '/build/static/tinymce/main.css';
        if (opts.content_css !== undefined) {
            contentCss += ',' + opts.content_css;
            // Remove it, we are gonna merge opts with options
            delete opts.content_css;
        }

        var options = Object.assign({
                plugins: "paste link replacelink autoresize image imagetools lists textcolor colorpicker table pagebreak hr contextmenu code fullscreen",
                resize: true,
                menubar: false,
                // statusbar: false, // needed for resize handle
                branding: false,
                toolbar: "paste formatselect fontsizeselect forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist | table pagebreak hr link replacelink image | contextmenu code fullscreen",
                paste_data_images: true,
                skin_url: '/build/static/tinymce/skins/lightgray',
                file_picker_types: 'image',
                pagebreak_separator: '<div style="page-break-after: always"><span style="display:none">&nbsp;</span></div>',
                language_url: '/build/static/tinymce/langs/fr_FR.js',
                content_css: contentCss,
                convert_urls: false,
                relative_urls: 0,
                remove_script_host: 0,
                toolbar_drawer: 'floating',

                // custom image picker
                file_picker_callback: function(cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function() {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function() {
                            // Note: Now we need to register the blob in TinyMCEs image blob
                            // registry. In the next release this part hopefully won't be
                            // necessary, as we are looking to handle it internally.
                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            // call the callback and populate the Title field with the file name
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();
                },
            },
            opts
        );

        if (typeof selectorOrTarget === 'string') {
            options.selector = selectorOrTarget;
        } else {
            options.target = selectorOrTarget;
        }

        // tinymce.remove();
        var editor = tinymce.init(options);

        return new Editor(editor, options);

    }
}

export { Editor };
