/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Jquery
window.$ = require('jquery');

// My WYSIWYG EDITOR
import { Editor } from './modules/wysiwyg';

// Make it available
window.Editor = Editor;

// Generic CSS (Bootstrap (with custom theme) + main css etc etc)
require('../scss/layout.scss');

require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');
